<template>
	<div class="bigBox">
		<h2>工作台</h2>
		<div class="newsBox margin-top20 row-me flex-sb">
			<div class="">
				<dl class="row-me row-center">
					<dt class="margin30"><img src="../../assets/img/mrtx.png" class="width100 height100"></dt>
					<dd>
						<b>管理员</b>
						<span class="row-me margin-top15"><img src="../../assets/img/icon_sj.png">&nbsp;{{userMoilie}}
						</span>
					</dd>
				</dl>
			</div>
			<div class="row-me">
				<div class="nums row-me row-center">
					<span>设备总数</span>
					<span class="num">{{stagArr.deviceNumber}}</span>
				</div>
				<div class="nums row-me row-center">
					<span>客户总数</span>
					<span class="num">{{stagArr.connectionNumber}}</span>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import {
		systemIndexDataInfo
	} from "@/api/stagIng/stagIng.js"
	export default {
		data() {
			return {
				stagArr: [],
				userMoilie: ""
			}
		},



		mounted() {
			this.stagList()
			let user = JSON.parse(sessionStorage.getItem('user'))
			if (user && user !== '') {
				this.user = user
			}
			this.userMoilie = user.mobile
			// this.loginText()
		},
		methods: {
			async stagList() {
				const res = await systemIndexDataInfo()
				this.stagArr = res.data
				console.log(res)
			}
		}
	}
</script>

<style lang="scss" scoped>
	.nums {
		margin: 68px;
	}

	.num {
		color: #3D6EE2;
		font-size: 34px;
		margin: 0 20px;

	}

	.bigBox {
		background: #FFFFFF;
		margin: 20px;
		padding: 30px;
	}

	h2 {
		font-weight: 600;
	}

	.newsBox {
		height: 160px;
		background-image: url(../../assets/img/bg_grxx.png);
		background-repeat: no-repeat;
		margin: 20px auto;

	}

	::v-deep .el-button--primary {
		width: 100px;
		height: 40px;
	}
</style>